/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */
import { MapLayer } from "@iventis/domain-model/model/mapLayer";
import { Node } from "@iventis/domain-model/model/node";
import { NodeType } from "@iventis/domain-model/model/nodeType";
import { DomainLayer } from "@iventis/map-engine/src/state/map.state.types";
import { ViewByStatus } from "@iventis/map-engine/src/types/layer-view-by";
import { findNode, findNodesByType, getNodeNestedLevel } from "@iventis/tree-browser";
import { compareAsc } from "date-fns";

export type SidebarOrderUpdate = Pick<MapLayer, "id" | "sidebarOrder">;

/** Order array of layers with multiple ordinals. Uses sidebarOrder > createdAt */
export const orderLayers = (layers: MapLayer[]) =>
    [...layers].sort((a, b) => {
        const aOrder = a.sidebarOrder ?? Infinity;
        const bOrder = b.sidebarOrder ?? Infinity;
        if (aOrder !== bOrder) {
            return aOrder - bOrder;
        }

        return compareAsc(a.createdAt, b.createdAt);
    });

export const sortAndFilterSidebarNodes = (node: Node, layers: DomainLayer[]) => {
    const newNode = { ...node };
    if (layers?.length === 0) {
        return newNode;
    }
    const layerIdToLayerMapping = layers.reduce<Record<string, DomainLayer>>((acc, l) => ({ ...acc, [l.id]: l }), {});
    const sortedNodes = [...newNode.childNodes].sort((a, b) => getSidebarOrder(a, layers) - getSidebarOrder(b, layers));
    newNode.childNodes = sortedNodes.map((child) => {
        if (child.type === NodeType.Folder) {
            const sorted = sortAndFilterSidebarNodes(child, layers);
            return sorted;
        }
        if (child.type === NodeType.Layer) {
            child.childNodes = child.childNodes
                .filter((n) => (layerIdToLayerMapping[child.id]?.viewBy === ViewByStatus.DATA_FIELD ? n.type === NodeType.DataFieldListItem : n.type === NodeType.MapObject))
                .sort((a, b) => {
                    if (a.name === "") return 1;
                    if (b.name === "") return -1;
                    return a.name.localeCompare(b.name);
                });
        }
        return child;
    });
    return newNode;
};

// Layers are sorted by their sidebar. If a folder, find the first layer within the folder and use that
// If the order is null, place at the bottom
const getSidebarOrder = (node: Node, layers: MapLayer[]) => {
    if (node.type === NodeType.Layer) return layers.find((l) => l.id === node.id)?.sidebarOrder ?? Infinity;
    return findFirstLayer(node, layers)?.sidebarOrder ?? Infinity;
};

const findFirstLayer = (node: Node, layers: MapLayer[]): MapLayer => {
    if (node.childNodes?.length === 0) {
        return null;
    }
    const firstLayer = node.childNodes.find((child) => child.type === NodeType.Layer);
    if (firstLayer == null) {
        let layer = null;
        node.childNodes.forEach((child) => {
            if (layer == null) {
                const firstLayer = findFirstLayer(child, layers);
                layer = layers.find((l) => l.id === firstLayer?.id);
            }
        });
        return layer;
    }
    return layers.find((l) => l.id === firstLayer.id);
};
/** Updates the sidebarOrder after a layer has been moved */
export const updateSidebarOrder = (layers: MapLayer[], mapNodeId: string, layerIdsToMove: string[], belowLayerId?: string, indexDiff = 1): SidebarOrderUpdate[] => {
    const layersToBeMoved = layers.filter(({ id }) => layerIdsToMove.includes(id));
    const orderedLayers = orderLayers(layers).filter(({ id }) => !layerIdsToMove.includes(id));
    // Find index of the layer we want to move the layer above
    const movedLayerIndex = belowLayerId === mapNodeId || belowLayerId == null ? orderedLayers.length : orderedLayers.findIndex((layer) => layer.id === belowLayerId);
    const indexMod = indexDiff > 0 ? 0 : indexDiff * -1;
    // Remove layers which don't need to be updated
    const aboveLayers = orderedLayers.slice(0, movedLayerIndex + indexMod);
    const belowLayers = orderedLayers.slice(movedLayerIndex + indexMod);
    const updatedLayerOrder = [...aboveLayers, ...layersToBeMoved, ...belowLayers].map((layer, index) => ({ ...layer, sidebarOrder: index + 1 }));
    return getLayersWithSidebarOrderChange(layers, updatedLayerOrder);
};

/** Gets the layers with a sidebar order value that has changed */
const getLayersWithSidebarOrderChange = (layers: MapLayer[], updatedLayers: MapLayer[]): SidebarOrderUpdate[] =>
    updatedLayers.reduce((changedLayers, updatedLayer) => {
        const originalLayer = layers.find(({ id }) => id === updatedLayer.id);
        if (updatedLayer.sidebarOrder !== originalLayer.sidebarOrder) {
            return [...changedLayers, { id: updatedLayer.id, sidebarOrder: updatedLayer.sidebarOrder }];
        }
        return changedLayers;
    }, []);

/** When a sidebar drag finishes, move and reorder nodes */
export const handleSidebarDragEnd = (
    nodesDragged: Node[],
    belowNodeId: string,
    mapNode: Node,
    layers: MapLayer[],
    moveNodes: (nodes: Node[], newParentId: string) => void
): SidebarOrderUpdate[] | null => {
    const layerNodeIdsToUpdate = findNodesByType(nodesDragged, NodeType.Layer).map((node) => node.id);
    // If we are dropping on the bottom item
    if (belowNodeId === mapNode.id) {
        // Change all nodes to have the same parent as the tree
        const nodesMovingParents = nodesDragged.filter((node) => node.parentId !== mapNode.id && node.type === NodeType.Layer);
        moveNodes(nodesMovingParents, mapNode.id);
        return updateSidebarOrder(layers, mapNode.id, layerNodeIdsToUpdate, belowNodeId, -1);
    }

    const belowNode = findNode([mapNode], belowNodeId);
    if (!canNodeBeMovedInto(nodesDragged, belowNode.id, mapNode)) {
        return null;
    }

    // Change all nodes to have the same parent as the node we are moving too
    const nodesMovingParents = nodesDragged.filter((node) => node.parentId !== belowNode.parentId);
    moveNodes(nodesMovingParents, belowNode.parentId);
    // If below node is a folder then place above it's first child layer (without changing it's parent)
    return updateSidebarOrder(layers, mapNode.id, layerNodeIdsToUpdate, getBelowLayerId(belowNode, layers));
};

export const getNextSidebarOrderValue = (layers: DomainLayer[]): number => {
    const orderedLayers = layers.filter((l) => l?.sidebarOrder);
    if (orderedLayers.length > 0) {
        return Math.max(...orderedLayers.map((l) => l.sidebarOrder)) + 1;
    }
    return 1;
};

/** Gets the layer which is below the given node in the sidebar */
const getBelowLayerId = (node: Node, layers: MapLayer[]): string => {
    if (node.type === NodeType.Layer) {
        return node.id;
    }

    // Get all layer nodes
    const layerNodes = findNodesByType([node], NodeType.Layer);
    // Order the layers
    const orderedLayers = orderLayers(layers);

    let firstLayerNode: Node;

    // Got through the ordered layers and find the first layer node where the id matches
    for (const layer of orderedLayers) {
        const foundNode = layerNodes.find((node) => node.id === layer.id);
        if (foundNode) {
            firstLayerNode = foundNode;
            break;
        }
    }

    if (firstLayerNode == null) {
        throw new Error(`No layer node found for the group ${node.id}`);
    }

    return firstLayerNode?.id;
};

/**
 * Gets the maximum depth of the tree of the given node, starting from a depth of 0. Will not check the children of layers
 * @param node The node to find the max depth of
 * @param maxDepth Used for recursion, do not populate when calling
 * @returns Depth of tree
 */
export const getMaxTreeDepth = (node: Node, maxDepth = 0) => {
    if (!node || node.type === NodeType.Layer || !node.childNodes || node.childNodes.length === 0) {
        return maxDepth;
    }
    let currentMaxDepth = maxDepth;
    node.childNodes.forEach((childNode) => {
        const childDepth = getMaxTreeDepth(childNode, maxDepth + 1);
        currentMaxDepth = Math.max(currentMaxDepth, childDepth);
    });
    return currentMaxDepth;
};

/**
 * Returns if a node can be moved into i.e. if the move would create a tree that has more than 3 depth
 * as described in the acceptance criteria 3 of story 12036
 * @param itemsToMove Items to move into the below node
 * @param nodeToMoveToId Id of the node to move into
 * @param tree The whole tree
 * @returns true if node can be moved into, false otherwise
 */
export const canNodeBeMovedInto = (itemsToMove: Node[], nodeToMoveToId: string, tree: Node) => {
    const belowNode = findNode([tree], nodeToMoveToId);
    if (itemsToMove && belowNode?.type && belowNode?.type !== NodeType.Map) {
        const nestedLevel = getNodeNestedLevel(tree, belowNode);
        // Get the maximum depth of all the dragging nodes. Prevent moving if we attempt to create a depth of more than 3
        const maximumDepth = itemsToMove.reduce<number>((prev, curr) => Math.max(prev, getMaxTreeDepth(curr)), 0);

        if (maximumDepth + nestedLevel > 3) {
            return false;
        }
    }
    return true;
};
